import React from 'react'
import Thumbnail from '../components/Thumbnail/Thumbnail';
import data from '../API/data.json';
import Caroussel from '../components/Caroussel/Caroussel';
import './index.scss'

export default function Home() {
  return (
	<main>
		<Caroussel imgs={[process.env.PUBLIC_URL + '/accueil.jfif']} title='Chez vous, partout et ailleurs'/>
		<div className='wrapping home_grid'>
			{data.map(e =>  <Thumbnail title={e.title} cover={e.cover} id={e.id} key={e.id}></Thumbnail>)}
		</div>
	</main>
  )
}
