import React from 'react'
import './index.scss'

export default function Tag({label, ...props}) {
  return (
	<div className='tag'>
    {label}
  </div>
  )
}
