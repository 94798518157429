import React from 'react'
import './index.scss'

export default function Thumbnail({cover, title, id, ...props}) {
  return (
	<a href={`/details?id=${id}`} className='thumbnail' style={{backgroundImage: cover ? `url(${cover})` : 'none'}}>
      <h3>{title}</h3>
  </a> 
  )
}
 