import React, { useState } from 'react'
import './index.scss'

export default function Caroussel({imgs, ...props}) {
  const [index, setIndex] = useState(0);

  const title = props.title;

  return (
	<div className={title !== undefined ? 'caroussel_title-type caroussel wrapping' : 'caroussel wrapping'}>
    {imgs.length > 1 ? 
    <>
      <i className='fas fa-chevron-left caroussel_arrow' onClick={() => setIndex(index === 0 ? imgs.length - 1 : index - 1)}></i>
      <i className='fas fa-chevron-right caroussel_arrow caroussel_arrow_right' onClick={() => setIndex(index === imgs.length - 1 ? 0 : index + 1)}></i>
      <p className='caroussel_counter'>{`${index + 1}/${imgs.length}`}</p>
    </>
    :<></>
    }
    {title ? 
    <div className='caroussel_title wrapping'>
      <h1>{title}</h1>
    </div>
    :<></> 
    }
    <img src={imgs[index]} alt='' style={title !== undefined ?  {filter: 'brightness(70%)'} : {}}/>
  </div>
  )
}
