import React from 'react'
import './index.scss'

export default function NotFound() {

	return (
	  <main className='not_found'>
		<div>
			<h1>404</h1>
			<p>Oups! La page que vous demandez n'existe pas.</p>
		</div>
		<a href="/">Retourner sur la page d’accueil</a>
	  </main>
)}