import React, { useState } from 'react'
import './index.scss'

export default function Collapse({ title, children, ...props}) {

	const [opened, setOpened] = useState(props.opened || false)

  return (
	<div className={props.className ? 'dropdown ' + props.className : 'dropdown'} style={props.style}>
		<div className='dropdown_head' onClick={()=> {
			setOpened(!opened)
		}}>
			<h4>{title}</h4>
			{
				opened ? 
					<i className="fas fa-chevron-up dropdown_icon"></i>
				: 
					<i className="fas fa-chevron-down dropdown_icon"></i>
			}
		</div>
		{
			opened ? 
				<div className='dropdown_content'>
					{children}
				</div>
				:
				<></>
		}
	</div>
  )
}
