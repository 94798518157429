import React from 'react'
import { useSearchParams } from 'react-router-dom';
import NotFound from './404';
import data from '../API/data.json';
import Caroussel from '../components/Caroussel/Caroussel';
import Tag from '../components/Tag/Tag';
import Collapse from '../components/Collapse/Collapse';
import Stars from '../components/Stars/Stars';
import './index.scss'

export default function Details() {
	const [searchParms] = useSearchParams();
	const id  = searchParms.get('id')
	const house = data.find(e => e.id === id)

	if(id && house) {
		return (
			<main>
				<Caroussel imgs={house.pictures} />
				<section className='house_infos_section wrapping'>
					<div>
						<h1>{house.title}</h1>
						<p>{house.location}</p>
						<ul className='tag_list'>
							{house.tags.map(t => 
							<li key={t}>
								<Tag label={t} />
							</li>
							)}
						</ul>
					</div>
					<aside>
						<div className='house_owner'>
							<div>
								<p>{house.host.name.split(' ')?.[0]}</p>
								<p>{house.host.name.split(' ')?.[1]}</p>
							</div>
							<img id='profil_picture' src={house.host.picture} alt={house.host.name + ' photo'}></img>
						</div>
						<Stars amount={house.rating}/>
					</aside>
				</section>
				<section className='house_description_section wrapping'>
					<Collapse title='Description'>
						<p>{house.description}</p>
					</Collapse>
					<Collapse title='Équipements'>
						<ul style={{listStyle: 'none', padding: 0}}>
							{house.equipments.map(e => <li key={e}>{e}</li>)}
						</ul>
					</Collapse>
				</section>
			</main>
		  )
	}
		
	return <NotFound />
}