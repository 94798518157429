import React from 'react'
import StarFull from '../../elements/starFull.svg'
import StarEmpty from '../../elements/starEmpty.svg'
import './index.scss'

export default function Stars({amount, ...props}) {

	const stars = []

	for (let i = 0; i < 5; i++) {
		i < amount ? stars.push(<img className='star' src={StarFull} alt='star-full' key={i}/>) : stars.push(<img className='star' src={StarEmpty} alt='star-empty' key={i}/>)
  	}

  return (
	<div className='stars_container'>
		{
			stars
		}
	</div>
  )
}
