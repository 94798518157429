import Details from './pages/Details';
import NotFound from './pages/404';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import About from './pages/About';

export default function AppRouter() {
  return (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/about" element={<About />} />
			<Route path="/details" element={<Details />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	</BrowserRouter>
  )};