import React from 'react'
import './index.scss'

/**
 * Navbar
 * @param {{links:Array.<{
 * label:string,
 * route:string
 * }>}} link The list of link used in the app
 */

export default function NavBar({ links, ...props }) {
  return (
    <header>
      <nav className='navbar wrapping'>
        <img src={process.env.PUBLIC_URL + '/logo_color.svg'} alt='Logo' />
        <ul>
          {
            links.map(l => <li key={l.label}><a href={l.route}>{l.label}</a></li>)
          }
        </ul>
      </nav>
    </header>
  )
}
