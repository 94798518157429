import './index.scss'

import React from 'react';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import AppRouter from './Router';

export default function App() {
  return (
    <>
      <NavBar links={[
        {
          label: 'Accueil',
          route: '/'
        },
        {
          label: 'A Propos',
          route: '/about'
        },
      ]}/>
      <AppRouter />
      <Footer />
    </>
  );
}